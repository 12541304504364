.container {
  padding: 1.2em; 
  background: #ececec 
}
.wrapper {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
}
@media (max-width: 425px) {
  .wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
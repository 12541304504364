@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* Use */
/* { backgroundColor: 'var(--primary)'} */
:root {
  --primary: #e8296d;
  --secondary: #000000;
  --headers-color: transparent;
}

.auth-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth-header-menu {
  display: flex;
}

/* Antd mods */
.app-logo {
  height: 45px;
}

.ant-input-number,
.ant-picker {
  min-width: 100% !important;
}

.ant-menu-dark,
.ant-menu-light,
.ant-layout-sider-trigger {
  background: transparent !important;
}

body[data-theme='light'] .ant-layout-header {
  background: transparent !important;
}

body[class='theme--dark'] .main-logo-container {
  background: transparent !important;
}

.main-logo__item {
  background-image: url(../img/LogoKidneyC.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  height: 60px;
}

body[class='theme--dark'] .main-logo__item {
  background-image: url(../img/LogoKidneyC-dark.png);
}

body[data-theme='theme--light'] .ant-layout-sider {
  background-color: white !important;
}

/* .ant-layout-sider,
.ant-layout-header {
  background: var(--headers-color) !important;
} */

body[data-theme='theme--light'] .ant-layout-sider-trigger {
  color: black;
}

.ant-breadcrumb > span:last-child {
  color: var(--primary) !important;
}

.main-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 113px;
  margin: 0 12px;
  background-color: #fff;
}

h1 {
  font-size: 24px;
}

.p-0-card > .ant-card-body {
  padding: 0;
}

.page-title {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Cabin', sans-serif;
}

@media (min-width: 728px) {
  .layout-header {
    padding: 2.5rem 4rem;
  }
  /* .layout-main {
    padding: 0 4rem;
  } */
}

.rc-steps__item {
  border: 1px solid rgb(233, 233, 233);
  color: rgb(211, 211, 211);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

.rc-steps__item-active {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

@media (max-width: 425px) {
  .button-title {
    display: none !important;
  }
  .main-logo__item {
    background-size: 50%;
    height: 60px;
  }
}

body[data-theme='dark'] .text-gray {
  color: rgba(255, 255, 255, 0.568);
}

.form-item--label-hidden .ant-form-item-label {
  display: none;
}

.main-layout__breadcrumb ol {
  display: flex;
  list-style: none;
  padding: 0;
}

@media (max-width: 1024px) {
  .sub-menu-popup ul {
    max-height: 240px !important;
    overflow-y: auto;
  }
}

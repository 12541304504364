.options-popover__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.options-popover__theme-switch {
  margin-bottom: 15px;
}

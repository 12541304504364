.place-autocomplete-input {
  box-sizing: border-box;
  margin: 0;
  padding: 7px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 1.5;
  list-style: none;
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 4px 0 0 4px;
  transition: all 0.2s;
}

body.theme--dark .place-autocomplete-input {
  background-color: #141414;
  border: 1px solid #424242;
  color: #fff;
}

.place-autocomplete-input:hover {
  border-color: #f55386;
  border-inline-end-width: 1px;
}

.place-autocomplete-input:focus {
  border-color: #f55386;
  box-shadow: 0 0 0 2px rgba(0, 37, 60, 0.22);
  border-inline-end-width: 1px;
  outline: 0;
}
